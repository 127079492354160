'use client';

import { Flex, chakra } from '@chakra-ui/react';
import { usePageTextContent } from 'context/pageTextContent';
import { useResponsiveness } from 'hooks/useResponsiveness';

export const BackgroundVideo = () => {
  const { content } = usePageTextContent<HomePageContent>();

  const { coverImageUrl, coverVideoUrl } = content.sectionOne;

  const { isMobile } = useResponsiveness();

  if (isMobile) return null;

  return (
    <Flex pos="absolute" w="full" top={0} right={0} zIndex={-1}>
      <chakra.video
        height="110vh"
        objectFit="cover"
        autoPlay
        muted
        loop
        poster={coverImageUrl}
      >
        <source src={coverVideoUrl} type="video/mp4" />
      </chakra.video>
    </Flex>
  );
};
