'use client';

import { ChangeEvent, FormEvent, useState } from 'react';
import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react';

import { Button } from 'components';
import { usePageTextContent } from 'context/pageTextContent';
import { Paths } from 'utils/paths';

export const SearchForm = () => {
  const [near, setNear] = useState('');

  const { content } = usePageTextContent<HomePageContent>();

  const { searchZipCodePlaceholder, searchZipCodeButton } = content.sectionOne;

  const onSubmit = (event: FormEvent<HTMLDivElement>) => {
    event.preventDefault();

    const isEmpty = near.trim() === '';

    if (isEmpty) return;

    window.open(`${Paths.SEARCH}?near=${near}`, '_self');
  };

  const onNearChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNear(event.target.value);
  };

  return (
    <Box as="form" width="100%" onSubmit={onSubmit}>
      <InputGroup
        maxW="20.625rem"
        h="2.625rem"
        visibility={['hidden', 'hidden', 'visible']}
        display={['none', 'none', 'flex']}
      >
        <Input
          bg="white"
          h="full"
          placeholder={searchZipCodePlaceholder}
          _placeholder={{
            color: 'gray.550',
            fontWeight: 300,
          }}
          onChange={onNearChange}
          value={near}
        />

        <InputRightElement w="6.25rem" h="full">
          <Button
            bg="zmyle.secondary"
            h="full"
            _hover={{ bg: 'zmyle.secondary' }}
            _active={{ bg: 'zmyle.primary' }}
            type="submit"
          >
            {searchZipCodeButton}
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};
