'use client';

import { Box, Icon } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

import { Button } from 'components';
import { useMobileNavigation } from 'context/mobileNavigation';
import { usePageTextContent } from 'context/pageTextContent';

export const MobileSearchButton = () => {
  const { content } = usePageTextContent<HomePageContent>();

  const { searchZipCodeButton } = content.sectionOne;

  const { onOpenNavigation } = useMobileNavigation();

  const handleOpenSearchMenu = () => {
    onOpenNavigation('search');
  };

  return (
    <Box
      alignSelf="flex-start"
      visibility={['visible', 'visible', 'hidden']}
      display={['unset', 'unset', 'none']}
    >
      <Button
        mt="3.25rem"
        h="2.25rem"
        bg="white"
        color="blue.800"
        fontSize="0.975rem"
        fontWeight="bold"
        onClick={handleOpenSearchMenu}
        leftIcon={<Icon as={FaSearch} fontSize="1rem" color="zmyle.primary" />}
      >
        {searchZipCodeButton}
      </Button>
    </Box>
  );
};
