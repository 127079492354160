'use client';

import { HStack, Icon } from '@chakra-ui/react';
import { FaCcMastercard, FaCcVisa, FaPaypal } from 'react-icons/fa';

import { Img } from 'components';
import { usePageTextContent } from 'context/pageTextContent';

export const PaymentProviders = () => {
  const { content } = usePageTextContent<HomePageContent>();

  const { giropayIconUrl } = content.sectionOne;

  return (
    <HStack
      spacing="0.5rem"
      w="full"
      mt={[0, 0, '3.75rem']}
      align="flex-start"
      justify={['center', 'center', 'flex-start']}
    >
      <Icon as={FaPaypal} color="white" fontSize="1.375rem" />

      <Icon as={FaCcVisa} color="white" fontSize="1.375rem" />

      <Icon as={FaCcMastercard} color="white" fontSize="1.375rem" />

      <Img
        src={giropayIconUrl}
        width={74}
        height={34}
        visibility={['visible', 'visible', 'hidden']}
        display={['unset', 'unset', 'none']}
        alt="Giropay"
      />
    </HStack>
  );
};
