import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/accordion/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/alert/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/avatar/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/breadcrumb/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/card/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/checkbox/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/close-button/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/color-mode/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/control-box/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/counter/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/css-reset/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/editable/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/focus-lock/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/form-control/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/hooks/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/icon/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/image/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/layout/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/media-query/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/menu/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/modal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/number-input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/pin-input/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/popover/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/popper/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/portal/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/progress/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/radio/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/react-env/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/react/dist/chunk-QAITB7GG.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/select/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/skeleton/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/skip-nav/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/slider/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/spinner/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/stat/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/stepper/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/switch/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-5PL47M24.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-7FWEOSAE.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-DMO4EI7P.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-FDQH4LQI.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-I77ZCDZJ.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-MFVQSVQB.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-UIGT7YZF.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-ZHQNHOQS.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/system/dist/chunk-ZJJGQIVY.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/table/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/tabs/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/tag/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/textarea/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/tooltip/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/transition/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/@chakra-ui/visually-hidden/dist/index.mjs");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/public/assets/icons/category-10000.png");
import(/* webpackMode: "eager" */ "/app/public/assets/icons/category-11000.png");
import(/* webpackMode: "eager" */ "/app/public/assets/icons/category-12000.png");
import(/* webpackMode: "eager" */ "/app/public/assets/icons/category-13000.png");
import(/* webpackMode: "eager" */ "/app/src/app/(HomePage)/components/CoverSection/BackgroundVideo.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(HomePage)/components/CoverSection/MobileSearchButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(HomePage)/components/CoverSection/PaymentProviders.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(HomePage)/components/CoverSection/SearchForm.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/(HomePage)/components/GiftcardSection/GiftcardIcon.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Button/Button.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/IconButton/IconButton.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Img/Img.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Input/CurrencyInput.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Input/Input.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Input/PercentageInput.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Input/Textarea.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/components/PageContainer.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/components/PageContent.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/Layout.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Layout/useLayout.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Link/Link.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Network/NetworksGrid.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/Outlet/OutletsGrid.tsx");
import(/* webpackMode: "eager" */ "/app/src/context/pageTextContent.tsx")