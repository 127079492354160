'use client';

import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

type Content = Record<string, any> & {
  seo?: SEO;
};

interface PageTextContentData<T extends Content> {
  content: T;
}

const PageTextContentContext = createContext({} as PageTextContentData<any>);

interface Props extends PropsWithChildren {
  content: Content;
}

export const PageTextContentContextProvider = (props: Props) => {
  const { children, content } = props;

  const value = useMemo(() => ({ content }), [content]);

  return (
    <PageTextContentContext.Provider value={value}>
      {children}
    </PageTextContentContext.Provider>
  );
};

type UsePageTextContent = <T extends Content>() => PageTextContentData<T>;

export const usePageTextContent: UsePageTextContent = () =>
  useContext(PageTextContentContext);
