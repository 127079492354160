'use client';

import { ReactNode, useMemo } from 'react';
import { Center, Icon, ListIcon } from '@chakra-ui/react';
import { MdPayment } from 'react-icons/md';
import { FiSend } from 'react-icons/fi';

import { Img } from 'components';
import { usePageTextContent } from 'context/pageTextContent';

type TGiftcardIcon = 'zmyle' | 'send' | 'buy';

interface Props {
  icon: TGiftcardIcon;
}

export const GiftcardIcon = (props: Props) => {
  const { icon } = props;

  const { content } = usePageTextContent<HomePageContent>();

  const { zmyleIconUrl } = content.sectionTwo;

  const iconElement = useMemo(() => {
    const icons: Record<Exclude<TGiftcardIcon, 'zmyle'>, ReactNode> = {
      buy: <Icon as={MdPayment} fontSize="1.25rem" />,
      send: <Icon as={FiSend} fontSize="1.25rem" />,
    };

    if (icon === 'zmyle') {
      return (
        <Img
          src={zmyleIconUrl}
          mt="auto"
          mb="0.25rem"
          alt="Zmyle"
          width="2rem"
          height="2rem"
          rounded="md"
          boxShadow="lg"
        />
      );
    }

    return (
      <Center
        w="2rem"
        h="2rem"
        p="0.25rem"
        bg="zmyle.primary"
        rounded="md"
        color="white"
        boxShadow="lg"
      >
        {icons[icon]}
      </Center>
    );
  }, [icon]);

  return <ListIcon as={() => iconElement} boxShadow="0px 5px 15px #00000029" />;
};
